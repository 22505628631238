import React, { useState } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";
import exampleWork from "./Assets/exampleWork.svg";
import herosectionImage from "./Assets/herosectionImage.svg";

const HeroSection = () => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    const gradientX = useMotionValue(0.5);
    const gradientY = useMotionValue(0.5);

    const background = useTransform(
        [gradientX, gradientY],
        ([x, y]) =>
            `conic-gradient(from 0deg at ${x * 100}% ${y * 100}%, 
      #9e2a2b, #1c1c1c, #eb1d36, #1f1f1f, #9e2a2b)`
    );

    const handleMouseMove = (e) => {
        setCursorPosition({ x: e.clientX, y: e.clientY });
        gradientX.set(e.clientX / window.innerWidth);
        gradientY.set(e.clientY / window.innerHeight);
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    };

    const textVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0, transition: { duration: 1, delay: 0.3 } },
    };

    const imageVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1, transition: { duration: 1, delay: 0.5 } },
    };

    return (
        <div
            className="relative w-full h-screen flex items-center justify-center overflow-hidden"
            onMouseMove={handleMouseMove}
        >
            {/* Background Gradient */}
            <motion.div
                style={{
                    background,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: -1,
                }}
                className="w-full h-full"
            />

            {/* Foreground Content */}
            <div className="relative w-11/12 h-full flex items-center justify-center max-md:flex-col max-md:justify-center">
                {/* Cursor Blur Animation */}
                <motion.div
                    id="cursorBlur"
                    className="absolute w-[60px] h-[60px] rounded-full bg-transparent pointer-events-none"
                    style={{
                        backdropFilter: "blur(10px)",
                        WebkitBackdropFilter: "blur(10px)",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10,
                    }}
                    animate={{
                        x: cursorPosition.x,
                        y: cursorPosition.y,
                    }}
                />

                {/* Text Content */}
                <motion.div
                    className="flex flex-col justify-center w-1/2 relative z-10 max-md:w-full max-md:mt-8 max-md:px-4"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <motion.p
                        className="font-poppins font-bold text-[100px] text-left leading-[124px] flex items-center tracking-[-0.03em] text-white max-md:text-[40px] max-md:leading-[50px]"
                        variants={textVariants}
                    >
                        Dijital Dünyanın <br />
                        Yaratıcı Gücü
                    </motion.p>
                    <motion.p
                        className="font-poppins font-light text-[24px] text-left leading-[30px] flex items-center tracking-[-0.03em] text-white pl-[60px] max-md:text-[16px] max-md:leading-[24px] max-md:pl-0"
                        variants={textVariants}
                    >
                        Markanızın dijitalde yükselişi için buradayız. Horus Medya olarak,
                        sosyal <br /> medyanın hızla değişen dünyasında fark yaratacak
                        çözümler üretiyor,<br /> markanızı kalabalıktan sıyırıyoruz.
                    </motion.p>
                </motion.div>

                {/* Hero Section Image */}
                <motion.div
                    className="w-2/5 relative z-10 max-md:w-full max-md:mt-8"
                    variants={imageVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <img
                        src={herosectionImage}
                        alt="Hero Section"
                        className="w-full h-full object-cover"
                    />
                </motion.div>
            </div>
        </div>
    );
};

export default HeroSection;
