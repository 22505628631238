import React, { useState, useEffect, useCallback } from "react";
import strategy from "./Assets/strategy.svg";

const WhyHorus = () => {
    const [currentSection, setCurrentSection] = useState(0);
    const [isFixed, setIsFixed] = useState(false);
    const [hasCompleted, setHasCompleted] = useState(false);

    const sections = [
        {
            image: strategy,
            title: "Özel Stratejiler",
            subtitle: "Markanızın hikayesini öne çıkaran, özgün planlar."
        },
        {
            image: strategy,
            title: "Yaratıcı İçerik",
            subtitle: "Etkileyici ve dikkat çeken içeriklerle dijital dünyada fark yaratın.",
        },
        {
            image: strategy,
            title: "Dinamik Ekip",
            subtitle: "Yenilikleri sürekli takip eden bir ekiple tanışmaya hazır olun."
        }
    ];

    const handleScroll = useCallback(() => {
        const element = document.getElementById('scroll-section');
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (rect.top <= viewportHeight / 2 && rect.bottom >= viewportHeight) {
            setIsFixed(true);

            // Calculate progress based on scroll position
            const startPosition = viewportHeight / 2;
            const totalScrollDistance = rect.height - viewportHeight;
            const currentScroll = startPosition - rect.top;
            const progress = currentScroll / totalScrollDistance;
            const clampedProgress = Math.max(0, Math.min(1, progress));

            // Calculate current section based on progress
            const newSectionIndex = Math.floor(clampedProgress * 3);

            if (!hasCompleted && newSectionIndex !== currentSection && newSectionIndex < 3) {
                setCurrentSection(newSectionIndex);
            }

            // Check if we've reached the end
            if (clampedProgress >= 0.95 && !hasCompleted) {
                setHasCompleted(true);
                setCurrentSection(2); // Stay on last section
            }
        } else {
            setIsFixed(false);
            if (rect.top > viewportHeight / 2) {
                setCurrentSection(0);
                setHasCompleted(false);
            }
        }
    }, [currentSection, hasCompleted]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <section className="bg-[#1f1f1f] min-h-screen text-white py-10 flex flex-col justify-center overflow-x-hidden">
            {/* Header */}
            <div className="flex flex-row items-center justify-center gap-x-4 w-4/5 mx-auto max-md:flex-col max-md:gap-y-2">
                <p className="text-white text-[75px] font-semibold max-md:text-[40px]">
                    Neden
                </p>
                <p className="text-[#E70000] text-[75px] font-semibold max-md:text-[40px]">
                    Horos Medya?
                </p>
            </div>

            {/* Description */}
            <p className="text-[24px] text-white w-4/5 mx-auto text-center max-md:text-[16px] max-md:leading-[24px] max-md:mt-4">
                Çünkü
                <span className="font-bold"> detaycıyız, </span>
                yaratıcıyız ve her zaman işimizin başındayız...
            </p>

            {/* Scroll Section */}
            <div id="scroll-section" className="relative h-[300vh] max-md:h-[200vh]">
                <div className={`w-full h-screen ${isFixed ? 'fixed top-0' : 'absolute'}`}>
                    {/* Progress Circle */}
                    <div className="absolute top-1/2 right-[10%] -translate-y-1/2 max-md:top-auto max-md:bottom-[5%] max-md:right-1/2 max-md:translate-x-1/2 max-md:-translate-y-0">
                        <div className="w-[100px] h-[100px] rounded-full border-2 border-gray-700 relative max-md:w-[60px] max-md:h-[60px]">
                            <svg className="w-full h-full transform -rotate-90">
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="45"
                                    fill="none"
                                    stroke="#E70000"
                                    strokeWidth="4"
                                    strokeDasharray="283"
                                    strokeDashoffset={283 - ((currentSection + 1) / 3) * 283}
                                    className="max-md:transform max-md:scale-[0.6] max-md:origin-center"
                                />
                            </svg>
                            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl font-bold max-md:text-lg">
                                {currentSection + 1}
                            </span>
                        </div>
                    </div>

                    {/* Content Sections */}
                    <div className="w-full h-full flex items-center justify-center relative overflow-hidden">
                        {sections.map((section, index) => {
                            const position = index - currentSection;
                            const isActive = index === currentSection;
                            const isBehind = index < currentSection;

                            return (
                                <div
                                    key={index}
                                    className="absolute w-full transition-all duration-700 ease-out"
                                    style={{
                                        transform: `translateX(${position * 100}%)`,
                                        opacity: isActive ? 1 : isBehind ? 0 : 0.3,
                                        zIndex: isActive ? 2 : 1,
                                        filter: isBehind ? 'blur(4px)' : 'none'
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundImage: `url(${section.image})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                        }}
                                        className="w-full h-[315px] mx-auto flex flex-col items-center justify-center max-md:h-[200px]"
                                    >
                                        <p className="text-[140px] font-semibold max-md:text-[60px]">
                                            {section.title}
                                        </p>
                                    </div>
                                    <p className="text-[20px] font-regular text-center max-md:text-[14px] max-md:leading-[20px] max-md:px-4">
                                        {section.subtitle}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyHorus;