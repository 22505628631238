import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const PortfolioSection = () => {
    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end start"],
        once: true
    });

    const portfolioItems = [
        {
            id: 1,
            image: "https://via.placeholder.com/400x250",
            title: "Graph",
            description: "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 2,
            image: "https://via.placeholder.com/400x250",
            title: "500 Chow",
            description: "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 3,
            image: "https://via.placeholder.com/400x250",
            title: "Graph",
            description: "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
        {
            id: 4,
            image: "https://via.placeholder.com/400x250",
            title: "Graph",
            description: "Çünkü detaycıyız, yaratıcıyız ve her zaman işimizin başındayız. Horus Medya olarak, markanızı en ince ayrıntısına kadar analiz ederiz.",
        },
    ];

    const x1 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]);
    const x2 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]);
    const x3 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]);
    const x4 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]);

    const y1 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]);
    const y2 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, -300]);
    const y3 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]);
    const y4 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 0, 300]);

    const scale1 = useTransform(scrollYProgress, [0, 0.3, 0.5, 0.7, 1], [0.3, 0.6, 1, 1, 1]);
    const scale2 = useTransform(scrollYProgress, [0, 0.3, 0.5, 0.7, 1], [0.3, 0.6, 1, 1, 1]);
    const scale3 = useTransform(scrollYProgress, [0, 0.3, 0.5, 0.7, 1], [0.3, 0.6, 1, 1, 1]);
    const scale4 = useTransform(scrollYProgress, [0, 0.3, 0.5, 0.7, 1], [0.3, 0.6, 1, 1, 1]);

    const rotate1 = useTransform(scrollYProgress, [0, 0.5, 1], [-15, 0, 0]);
    const rotate2 = useTransform(scrollYProgress, [0, 0.5, 1], [5, 0, 0]);
    const rotate3 = useTransform(scrollYProgress, [0, 0.5, 1], [25, 0, 0]);
    const rotate4 = useTransform(scrollYProgress, [0, 0.5, 1], [-5, 0, 0]);

    const cardTransforms = [
        { x: x1, y: y1, scale: scale1, rotate: rotate1 },
        { x: x2, y: y2, scale: scale2, rotate: rotate2 },
        { x: x3, y: y3, scale: scale3, rotate: rotate3 },
        { x: x4, y: y4, scale: scale4, rotate: rotate4 },
    ];

    return (
        <div
            ref={containerRef}
            className="h-[200vh] relative bg-[#1f1f1f]"
        >
            <p className="text-white text-4xl font-bold text-center">Portfolio</p>
            <div className="sticky top-0 h-screen flex items-center justify-center overflow-hidden">
                <motion.div
                    className="relative w-full h-full flex items-center justify-center"
                    style={{
                        perspective: 2000
                    }}
                >
                    {portfolioItems.map((item, index) => (
                        <motion.div
                            key={item.id}
                            className="absolute w-[400px] bg-white rounded-xl overflow-hidden shadow-2xl cursor-pointer"
                            style={{
                                x: cardTransforms[index].x,
                                y: cardTransforms[index].y,
                                scale: cardTransforms[index].scale,
                                rotate: cardTransforms[index].rotate,
                                transformStyle: "preserve-3d",
                                transformOrigin: "center center"
                            }}
                            whileHover={{
                                scale: 1.05,
                                transition: { duration: 0.2 }
                            }}
                        >
                            <img
                                src={item.image}
                                alt={item.title}
                                className="w-full h-48 object-cover"
                            />
                            <div className="p-6">
                                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                                    {item.title}
                                </h2>
                                <p className="text-gray-600 text-sm leading-relaxed">
                                    {item.description}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default PortfolioSection;
