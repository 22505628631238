import React, { useState, useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const Services = () => {
    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start start", "end end"]
    });

    const services = [
        {
            title: "Marka Danışmanlığı",
            description: "Markanızın kişiliğini oluşturuyor, hikayenizi dijital dünyaya etkili bir şekilde yansıtıyoruz. Markanızın kişiliğini oluşturuyor, hikayenizi dijital dünyaya etkili bir şekilde yansıtıyoruz."
        },
        {
            title: "Web Sitesi Tasarımı",
            description: "Markanıza özel özgün ve kullanıcı dostu web siteleri tasarlıyoruz. Mobil uyumluluk ve hız bizim işimiz."
        },
        {
            title: "Dijital Pazarlama",
            description: "Markanızı dijital dünyada öne çıkaracak stratejiler geliştiriyor, Google ve sosyal medya reklamları ile hedef kitlenizi büyütüyoruz."
        },
        {
            title: "Sosyal Medya Yönetimi",
            description: "Markanızı sosyal medyada yıldız yapıyor, etkileşimlerinizi zirveye çıkarıyoruz."
        },
        {
            title: "Prodüksiyon Hizmetleri",
            description: "Tanıtım videoları, reklam filmleri ve belgesellerle markanızı görsel olarak taçlandırıyoruz."
        }
    ];

    // Create individual progress values for each service
    const progress1 = useTransform(scrollYProgress, [0, 0.2], [226.2, 0]);
    const progress2 = useTransform(scrollYProgress, [0.2, 0.4], [226.2, 0]);
    const progress3 = useTransform(scrollYProgress, [0.4, 0.6], [226.2, 0]);
    const progress4 = useTransform(scrollYProgress, [0.6, 0.8], [226.2, 0]);
    const progress5 = useTransform(scrollYProgress, [0.8, 1], [226.2, 0]);

    const progressArray = [progress1, progress2, progress3, progress4, progress5];

    // Create opacity values for each service
    const opacity1 = useTransform(scrollYProgress, [0, 0.2, 0.4], [1, 1, 0]);
    const opacity2 = useTransform(scrollYProgress, [0.2, 0.4, 0.6], [1, 1, 0]);
    const opacity3 = useTransform(scrollYProgress, [0.4, 0.6, 0.8], [1, 1, 0]);
    const opacity4 = useTransform(scrollYProgress, [0.6, 0.8, 1], [1, 1, 0]);
    const opacity5 = useTransform(scrollYProgress, [0.8, 1], [1, 1]);

    const opacityArray = [opacity1, opacity2, opacity3, opacity4, opacity5];

    return (
        <div ref={containerRef} className="relative w-full bg-[#1f1f1f]">
            {services.map((service, index) => (
                <motion.div
                    key={index}
                    className="sticky top-0 h-screen flex flex-col justify-center items-center lg:p-8"
                    style={{
                        opacity: opacityArray[index]
                    }}
                >
                    <div className="relative w-full max-w-7xl mx-auto bg-[#E70000] rounded-3xl h-[80vh] overflow-hidden max-md:w-11/12">
                        {/* Progress Circle */}
                        <div className="absolute top-8 right-8 w-20 h-20">
                            <svg className="w-full h-full transform -rotate-90">
                                <circle
                                    cx="40"
                                    cy="40"
                                    r="36"
                                    fill="none"
                                    stroke="#1f1f1f"
                                    strokeWidth="2"
                                    className="opacity-25"
                                />
                                <motion.circle
                                    cx="40"
                                    cy="40"
                                    r="36"
                                    fill="none"
                                    stroke="white"
                                    strokeWidth="4"
                                    strokeDasharray="226.2"
                                    style={{
                                        strokeDashoffset: progressArray[index]
                                    }}
                                />
                            </svg>
                            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-medium">
                                {index + 1}/{services.length}
                            </span>
                        </div>

                        {/* Content */}
                        <div className="flex flex-col md:flex-row h-full p-12 gap-8">
                            <div className="flex-1 flex flex-col justify-center">
                                <motion.p
                                    className="text-[#1f1f1f] text-2xl md:text-4xl mb-4"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6 }}
                                >
                                    Hizmetlerimiz
                                </motion.p>
                                <motion.h2
                                    className="text-white text-4xl md:text-6xl font-bold mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6, delay: 0.2 }}
                                >
                                    {service.title}
                                </motion.h2>
                            </div>
                            <motion.div
                                className="flex-1 flex items-center"
                                initial={{ opacity: 0, x: 20 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.6, delay: 0.4 }}
                            >
                                <p className="text-white text-xl md:text-3xl font-light leading-relaxed">
                                    {service.description}
                                </p>
                            </motion.div>
                        </div>
                    </div>
                </motion.div>
            ))}
        </div>
    );
}

export default Services;