import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation, useScroll, useTransform, useMotionValue } from "framer-motion";
import PortfolioSection from "./PortfolioSection.jsx";
import { useInView } from "react-intersection-observer";
import WhyHorus from "./WhyHorus.jsx";
import MarqueeSection from "./MarqueeSection.jsx";
import Footer from "./Footer.jsx";
import Services from "./Services.jsx";
import HeroSection from "./HeroSection.jsx";
import HoruseyeSection from "./HoruseyeSection.jsx";

const Home = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ threshold: 0.2 });
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);
    return (
        <div className="">
            <HeroSection />
            <MarqueeSection />
            <WhyHorus />
            <HoruseyeSection />
            <Services />
            <PortfolioSection />
            <Footer />
        </div >
    );
};

export default Home;